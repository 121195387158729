import MaterialLink from "@/components/common/MaterialLink";
import NotFoundSeo from "@/components/seo/NotFoundSeo";
import { Box, Stack, Typography } from "@mui/material";
import { useLocalization } from "providers/LocalizationProvider";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouter } from "next/router";

const Custom404 = () => {
  const { getLocalizedString } = useLocalization();
  const { asPath } = useRouter();

  useEffect(() => {
    Sentry.captureException(new Error(`User reached 404 page from ${asPath}`));
  }, [asPath]);

  return (
    <>
      <NotFoundSeo />
      <Box
        sx={{
          background: "#fafafa",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: 1,
          width: 1,
          gap: 2,
        }}
      >
        <Typography variant="h1">{getLocalizedString("404")}</Typography>
        <Typography variant="h3">
          {getLocalizedString("pageNotFound")}
        </Typography>
        <Stack direction="row" spacing={2} mt={2}>
          <MaterialLink href="https://dreamcase.eu" color="#000000">
            {getLocalizedString("backHome")}
          </MaterialLink>
        </Stack>
      </Box>
    </>
  );
};

export default Custom404;
