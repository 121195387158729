import { NextSeo } from "next-seo";

const NotFoundSeo = () => {
  const title = "Not found | DreamCase";
  const description = "Page not found.";
  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        title,
        description,
        site_name: "DreamCase",
      }}
      twitter={{
        handle: "@dreamcased",
        site: "@dreamcase",
        cardType: "summary",
      }}
    />
  );
};

export default NotFoundSeo;
